import React, { useEffect, useRef } from 'react';
// import useLogout from './useLogout';
import { AmplifyAuth } from '../services/amplifyAuth'
import { useApplication } from '../services/application'

export default function useInactivityLogout(timeout = 60 * 60 * 1000) {
  const logout = useLogout();
  const timerIdRef = useRef(null);
  

  useEffect(() => {
    const resetTimer = () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
      timerIdRef.current = setTimeout(() => {
        logout(true); 
      }, timeout);
    };

    resetTimer();

    // Listen for user activity events
    const events = ['mousemove', 'keydown', 'click', 'touchstart'];
    events.forEach((evt) => window.addEventListener(evt, resetTimer));

    // Cleanup when component unmounts or dependencies change
    return () => {
      events.forEach((evt) => window.removeEventListener(evt, resetTimer));
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, [logout, timeout]);

  return null;
}

function useLogout() {
    const application = useApplication()
    return React.useCallback(() => {
      AmplifyAuth.signOut()
        .then(() => {
            application.authorize()
          window.location.href = '/sign-in'
        })
        .catch(console.warn)
    }, [application]);
  }