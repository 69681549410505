import { Grid, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core'
import React, { useCallback } from 'react'
import { Button } from '../../common/Buttons'
import { ModalDialog } from '../../common/Dialogs'
import { useTrans } from '../../../services/i18n'
import { useAlertContext } from '../../../contexts/AlertContext'
import Typography from '../../common/Typography'
import { getInitialValues, useScheme } from './helpers'
import { useForm } from '../../../hooks/useForm'
import { TextField } from '../../common/Inputs'
import { DatePicker } from '../../common/Inputs'

const formOptions = {
  validateOnMount: false,
  validateOnChange: true,
  enableReinitialize: true,
}

export default function ReplyQuoteRequest({
  quote,
  open,
  onClose,
  handleAction,
  fetchList,
}) {
  const { trans } = useTrans()
  const schema = useScheme()
  const { successAlert, errorAlert, infoAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)

  const closeModal = () => {
    resetForm()
    onClose()
  }

  const onSubmit = useCallback(
    values => {
      if (getFieldProps('isDenied').value) {
        values.amount = null
      }
      if (typeof handleAction !== 'function') return
      setIsSend(true)
      Promise.resolve(handleAction(values))
        .then(res => {
          if (res.error && res.error.message) {
            errorAlert()
          } else {
            closeModal()
            successAlert(res.message)
            fetchList()
          }
        })
        .catch(errorAlert)
        .finally(() => setIsSend(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeModal, handleAction, infoAlert, errorAlert, successAlert]
  )

  const {
    getFieldProps,
    handleSubmit,
    values,
    resetForm,
  } = useForm(schema, getInitialValues(quote), onSubmit, formOptions)

  return (
    <ModalDialog
      open={open}
      onClose={closeModal}
      title={values.id ? trans('quote-response') : trans('add-user')}
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSend}
              onClick={closeModal}
            >
              {trans('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={isSend}
              pending={isSend}
              onClick={handleSubmit}
            >
              {trans('send')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography weight="600" variant="body1" fontFamily="lexend">
            {quote.user} - {quote.email}
          </Typography>

          <Typography
            style={{ margin: '10px 0' }}
            weight="600"
            variant="body1"
            fontFamily="lexend"
          >
            {trans('message')}
          </Typography>
          <Typography variant="body2" fontFamily="lexend">
            {quote?.userMessage}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox color='primary'/>}
              label="Deny"
              {...getFieldProps('isDenied')}
            />
            
          </FormGroup>
          <TextField
            label={trans('response-max')}
            maxLength={1000}
            multiline
            rows={5}
            {...getFieldProps('adminResponse')}
          />
        </Grid>
        {!getFieldProps('isDenied').value && <Grid item xs={12}>
          <TextField label={trans('amount')} type="number" {...getFieldProps('amount')} />
        </Grid>}
        {!getFieldProps('isDenied').value && <Grid item xs={12}>
          <DatePicker label={trans('expires')} {...getFieldProps('expires')} />
        </Grid>}
      </Grid>
    </ModalDialog>
  )
}
