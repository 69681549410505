import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { API } from '../../constants';
import { application } from '../../services/application';

export const INITIAL_STATE = {
  statistics: [
    {
      value: 0,
      text: 'Active',
      link: '/organizations?filter=active',
      color: '#17B471',
    },
    {
      value: 0,
      text: 'Expiring Soon',
      link: '/organizations?filter=expiresSoon',
      color: '#FFAC00',
    },
    {
      value: 0,
      text: 'Deactivated',
      link: '/organizations?filter=deactivated',
      color: '#FF5225',
    },
    {
      value: 0,
      text: 'Inactive',
      link: '/organizations?filter=inactive',
      color: '#E2222E',
    },
  ],
  recentlyClients: [],
  optionsChart: {
    credits: {
      enabled: false,
    },
    colors: [
      '#0fbfcf',
      '#b2b8cf',
      '#fe8a23',
      '#2445a7',
      '#17b471',
      '#9576ff',
      '#E2222Eaa',
    ],
    chart: {
      type: 'pie',
      renderTo: 'container',
    },
    title: {
      verticalAlign: 'middle',
      y: 75,
      floating: true,
      text: '',
    },
    plotOptions: {
      pie: {
        innerSize: '85%',
        size: '95%',
      },
    },
    series: [
      {
        animation: {
          duration: 750,
          easing: 'easeOutQuad',
        },
        series: {
          animation: {
            duration: 750,
            easing: 'easeOutQuad',
          },
        },
        dataLabels: {
          enabled: false,
        },
        data: [],
      },
    ],
  },
  numberAccounts: {
    expired: 0,
    limited: 0,
    advantage: 0,
    pro: 0,
    sapAdvantage: 0,
    appMax: 0,
    procurementPro: 0,
    percentExpired: 0,
    percentLimited: 0,
    percentAdvantage: 0,
    percentPro: 0,
    percentSapAdvantage: 0,
    percentAppMax: 0,
    percentProcurementPro: 0,
    total: 0,
  },
  mapChart: [],
  pending: false,
};

const getInformation = createAsyncThunk('business/getDashboard', (params) => {
  return application.call(API.DASHBOARD.GET, params);
});

function onFetchInformation(state, action) {
  const copyStatistics = [...state.statistics];
  copyStatistics[0].value = action.payload.statistics.actives;
  copyStatistics[1].value = action.payload.statistics.expiresSoon;
  copyStatistics[2].value = action.payload.statistics.deactivated;
  copyStatistics[3].value = action.payload.statistics.inactive;

  Object.assign(state, {
    statistics: [...copyStatistics],
    recentlyClients: action.payload.recentlyClients,
    pending: false,
    numberAccounts: { ...action.payload.numberAccounts },
    mapChart: [...action.payload.mapChart]
  });

  const copyChart = {...state.optionsChart}
  copyChart.series[0].data = [...action.payload.chart]
  copyChart.title.text = `<div><p style="font-size:70px;font-family: Manrope; color:#4e5978">${action.payload.numberAccounts.total}</p> <br/><br/> <p style="font-family: Manrope;font-size:14px;color:#888fa9">Total Accounts</p></div>`

  Object.assign(state, { optionsChart: {...copyChart} })
}

export const actions = {
  getInformation,
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getInformation.fulfilled, onFetchInformation)
    .addCase(getInformation.pending, (state) => {
      Object.assign(state, {
        pending: true,
      });
    })
    .addCase(getInformation.rejected, (state) => {
      Object.assign(state, {
        pending: false,
      });
    });
});
