import clsx from 'clsx'
import React from 'react'
import { useTrans } from '../../../services/i18n'
import Typography from '../../../components/common/Typography'
import { ACCOUNT_FEATURES, FEATURES_SHORT_NAMES } from '../../../constants/subscriptionSettings'

const BG_COLORS = {
  [ACCOUNT_FEATURES.EXPIRED]: '#0fbfcf',
  [ACCOUNT_FEATURES.LIMITED]: '#b2b8cf',
  [ACCOUNT_FEATURES.ADVANTAGE]: '#fe8a23',
  [ACCOUNT_FEATURES.PRO]: '#2445a7',
  [ACCOUNT_FEATURES.SAP_ADVANTAGE]: '#17b471',
  [ACCOUNT_FEATURES.APP_MAX]: '#9576ff',
  [ACCOUNT_FEATURES.PROCUREMENT_PRO]: '#E2222Eaa',
}

function TierBreakdown({ classes, tier, total, percentage }) {
  const { trans } = useTrans()
  return (
    <div className={clsx(classes.flex)}>
      <div
        className={classes.border}
        style={{ backgroundColor: BG_COLORS[tier], margin: '0 10px 0 0' }}
      ></div>
      <div style={{ textAlign: 'left' }}>
        <Typography weight="bold" variant="h6">
          {trans(FEATURES_SHORT_NAMES[tier])}
        </Typography>
        <Typography weight="bold" variant="h6">
          {total}
        </Typography>
        <Typography variant="body2" color="placeholder">
          {percentage}%
        </Typography>
      </div>
    </div>
  )
}

export default TierBreakdown
