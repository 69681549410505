import {
  EXISTS,
  SINGLE_VALUE_SCHEMA,
  DATE_SCHEMA,
  DEFAULT_STRING_STATE_SINGLE,
  DEFAULT_DATE_STATE,
  DOES_NOT_EXISTS,
  STRING_SCHEMA,
  DEFAULT_STRING_STATE,
  REQUIRED_STRING_FILTER_OPTIONS,
  IS_AFTER,
} from '../../constants/filter';
import { FILTER_COMPONENTS } from '../../components/generics/SearchTopPanel/FiltersMenu/Filters';
import dayjs from 'dayjs';
import * as AUTOCOMPLETE from '../../components/autocompletes/index';
import { ACCOUNT_FEATURES } from '../../constants/subscriptionSettings';

export const TODAY = new Date().toISOString().split('T')[0];
export const EXPIRES_SOON_MAX_DATE = new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0];
export const getInactiveDateValue = (days) => dayjs().subtract(days, 'day').unix();
export const INACTIVE_15_DAYS = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
export const INACTIVE_30_DAYS = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];

export const filterOptions = [
  {
    name: 'subscription-expired',
    value: 1,
    static: {
      filters: {
        hasSubscription: {
          type: EXISTS,
          value: ACCOUNT_FEATURES.EXPIRED,
        },
      },
    },
  },
  {
    name: 'subscription-limited',
    value: 1,
    static: {
      filters: {
        hasSubscription: {
          type: EXISTS,
          value: ACCOUNT_FEATURES.LIMITED,
        },
      },
    },
  },
  {
    name: 'subscription-advantage',
    value: 1,
    static: {
      filters: {
        hasSubscription: {
          type: EXISTS,
          value: ACCOUNT_FEATURES.ADVANTAGE,
        },
      },
    },
  },
  {
    name: 'subscription-pro',
    value: 1,
    static: {
      filters: {
        hasSubscription: {
          type: EXISTS,
          value: ACCOUNT_FEATURES.PRO,
        },
      },
    },
  },
  {
    name: 'subscription-advm',
    value: 1,
    static: {
      filters: {
        hasSubscription: {
          type: EXISTS,
          value: ACCOUNT_FEATURES.SAP_ADVANTAGE,
        },
      },
    },
  },
  {
    name: 'subscription-app',
    value: 1,
    static: {
      filters: {
        hasSubscription: {
          type: EXISTS,
          value: ACCOUNT_FEATURES.APP_MAX,
        },
      },
    },
  },
  {
    name: 'subscription-prom',
    value: 1,
    static: {
      filters: {
        hasSubscription: {
          type: EXISTS,
          value: ACCOUNT_FEATURES.PROCUREMENT_PRO,
        },
      },
    },
  },
  {
    name: 'salesforce-id',
    value: 'salesforceId',
    scheme: SINGLE_VALUE_SCHEMA,
    component: FILTER_COMPONENTS.TEXT,
    default: { ...DEFAULT_STRING_STATE_SINGLE },
    options: {
      label: 'salesforce-id',
    }
  },
  {
    name: 'uei-cage-code',
    value: 'ueiCageCode',
    scheme: SINGLE_VALUE_SCHEMA,
    component: FILTER_COMPONENTS.TEXT,
    default: { ...DEFAULT_STRING_STATE_SINGLE },
    options: {
      label: 'uei-cage-code',
    }
  },
  {
    name: 'owner',
    value: 'ownerName',
    scheme: SINGLE_VALUE_SCHEMA,
    component: FILTER_COMPONENTS.TEXT,
    default: { ...DEFAULT_STRING_STATE_SINGLE },
    options: {
      label: 'owner',
    }
  },
  {
    name: 'registered-at',
    value: 'createdAt',
    scheme: DATE_SCHEMA,
    component: FILTER_COMPONENTS.DATE,
    default: { ...DEFAULT_DATE_STATE },
    options: {
      label: 'registered-at',
    }
  },
  {
    name: 'Expires Date',
    value: 'afpdsExpirationDate',
    scheme: DATE_SCHEMA,
    component: FILTER_COMPONENTS.DATE,
    default: { ...DEFAULT_DATE_STATE },
    options: {
      label: 'expires',
    }
  },
  {
    name: 'status',
    value: 'status',
    component: FILTER_COMPONENTS.DROPDOWN,
    scheme: STRING_SCHEMA,
    default: { ...DEFAULT_STRING_STATE},
    options: {
      menuItems: REQUIRED_STRING_FILTER_OPTIONS,
    },
    autocomplete: AUTOCOMPLETE.StatusAutocomplete,
  },
  {
    name: 'Inactive Options',
    value: 'inactive',
    component: FILTER_COMPONENTS.DROPDOWN,
    scheme: STRING_SCHEMA,
    default: { ...DEFAULT_STRING_STATE},
    options: {
      menuItems: REQUIRED_STRING_FILTER_OPTIONS,
    },
    autocomplete: AUTOCOMPLETE.InactiveAutocomplete,
  },
  {
    name: 'Inactive Days',
    value: 'inactiveDays',
    component: FILTER_COMPONENTS.DATE,
    scheme: DATE_SCHEMA,
    default: { ...DEFAULT_DATE_STATE },
  },
  {
    name: 'Expires Options',
    value: 'expires-options',
    component: FILTER_COMPONENTS.DROPDOWN,
    scheme: STRING_SCHEMA,
    default: { ...DEFAULT_STRING_STATE},
    options: {
      menuItems: REQUIRED_STRING_FILTER_OPTIONS,
    },
    autocomplete: AUTOCOMPLETE.ExpiresAutocomplete,
  },
];
