import * as Yup from 'yup'
import makeValidationSchema from '../../../hooks/makeValidationSchema'

export const initialValues = {
  amount: null,
  adminResponse: '',
  isDenied: false
}

export const getInitialValues = item => {
  return Object.assign({}, initialValues, item)
}

export const useScheme = makeValidationSchema(() => {
  return Yup.object().shape({
    id: Yup.string().optional().nullable().default(undefined),
    isDenied: Yup.boolean(),
    amount: Yup.number().nullable(true).default(0),
    expires: Yup.date().nullable(true),
    adminResponse: Yup.string().required('Response is a required field').nullable().default(''),
  })
}
)
