import React, { useState, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import AppLayout from '../components/layouts/AppLayout'
import SignIn from '../views/Auth/SignIn'
import Dashboard from '../views/Dashboard'
import Clients from '../views/Clients'
import ClientsDitails from '../views/Clients/Details'
import { ApplicationEvents, useApplication } from '../services/application'
import useLogout from '../hooks/useLogout'
import RegisteredUsers from '../views/RegisteredUsers'
import RegisteredUserDetail from '../views/RegisteredUserDetail'
import UsfcrStaff from '../views/UsfcrStaff'
import Releases from '../views/Releases'
import GlobalSettings from '../views/GlobalSettings'
import SubscriptionSettings from '../views/SubscriptionSettings'
import FormDetail from '../views/GlobalSettings/components/formDetail'
import Profile from '../views/Profile'
import UsfcrStaffDetail from '../views/UsfcrStaff/Details'
import ReleaseDetail from '../views/Releases/Details'
import AddRelease from '../views/Releases/AddRelease'
import UpdateRelease from '../views/Releases/UpdateRelease'
import RegisterNewUser from '../views/RegisteredUsers/components/RegisterNewUser'
import TooltipsManagement from '../views/TooltipsManagement'
import RequestedQuotes from '../views/RequestedQuotes'
import RequestTemplates from '../views/RequestTemplates'
import RequestTemplateDetail from '../views/RequestTemplates/Details'
import EmailTemplates from '../views/EmailTemplates'
import EmailTemplateDetails from '../views/EmailTemplates/Details'
import AdminRoles from '../views/AdminRoles'
import AdminRolesDetail from '../views/AdminRoles/Details'
import CourseLevels from '../views/CourseLevels'
import OrganizationLogoReview from '../views/OrganizationLogoReview'
import TeamingOpportunities from '../views/TeamingOpportunities'

import { useSelector, useDispatch } from 'react-redux'
import { actions } from '../store/adminPortalRoles'
import { selectUserPermissions, isEnabled, selectUserRole } from '../selectors'
import useInactivityLogout from '../hooks/useInactivityLogout'

export const ROUTE_SIGNIN = { key: 'sign-in', path: 'sign-in', noAuth: true }

const notAuthOptions = {
  redirect: false ? '/dashboard' : null,
  noAuth: true,
}

const PublicRoutes = ({ children, authorized }) => {
  return !authorized ? children : <Navigate to="/dashboard" />
}

const PrivateRoutes = () => {
  const permissions = useSelector(selectUserPermissions)
  const userRole = useSelector(selectUserRole)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(actions.getRolesList())
  }, [dispatch])

  return (
    <AppLayout>
      <Routes>
        <Route path="/profile" element={<Profile />} />
        {isEnabled(permissions, 'dashboard') && (
          <>
            <Route exact path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </>
        )}
        {isEnabled(permissions, 'organization') && (
          <>
            <Route path="/organizations" element={<Clients />} />
          </>
        )}
        <Route path="/organizations/:id" element={<ClientsDitails />} />

        {isEnabled(permissions, 'registered-users') && (
          <>
            <Route path="/users" element={<RegisteredUsers />} />
            <Route path="/users/:id" element={<RegisteredUserDetail />} />
            {isEnabled(permissions, 'registered-users', '', 'create') && (
              <Route path="/register-new-user" element={<RegisterNewUser />} />
            )}
          </>
        )}
        {isEnabled(permissions, 'usfcr-staff') && (
          <Route path="/usfcr-staff" element={<UsfcrStaff />} />
        )}
        {isEnabled(permissions, 'usfcr-staff', 'add-user') && (
          <Route path="/usfcr-staff/:id" element={<UsfcrStaffDetail />} />
        )}

        {isEnabled(permissions, 'releases') && (
          <>
            <Route path="/releases" element={<Releases />} />

            <Route path="/releases/:id" element={<ReleaseDetail />} />

            {isEnabled(permissions, 'releases', '', 'create') && (
              <Route path="/releases/new" element={<AddRelease />} />
            )}
            {isEnabled(permissions, 'releases', '', 'edit') && (
              <Route path="/releases/update/:id" element={<UpdateRelease />} />
            )}
          </>
        )}

        {userRole?.key === 'admin' && (
          <>
            <Route path="/admin-portal-roles" element={<AdminRoles />} />
            <Route
              path="/admin-portal-roles/:roleKeyEdit"
              element={<AdminRolesDetail />}
            />
          </>
        )}

        {isEnabled(permissions, 'global-settings') && (
          <Route path="/global-settings" element={<GlobalSettings />} />
        )}
        {isEnabled(permissions, 'subscription-settings') && (
          <Route
            path="/subscription-settings"
            element={<SubscriptionSettings />}
          />
        )}
        {isEnabled(permissions, 'course-levels') && (
          <Route path="/course-levels" element={<CourseLevels />} />
        )}

        <Route path="/form/:id" element={<FormDetail />} />

        {isEnabled(permissions, 'tooltips-management') && (
          <Route path="/tooltips-management" element={<TooltipsManagement />} />
        )}

        {isEnabled(permissions, 'requested-quotes') && (
          <Route path="/requested-quotes" element={<RequestedQuotes />} />
        )}
        {isEnabled(permissions, 'request-templates') && (
          <>
            <Route path="/request-templates" element={<RequestTemplates />} />
            <Route
              path="/request-template/:id/:slug"
              element={<RequestTemplateDetail />}
            />
          </>
        )}

        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route path="/email-templates/:id" element={<EmailTemplateDetails />} />
        <Route path="/org-logo-review" element={<OrganizationLogoReview />} />
        <Route
          path="/teaming-opportunities"
          element={<TeamingOpportunities />}
        />
      </Routes>
    </AppLayout>
  )
}

const App = () => {
  const app = useApplication()
  const [authorized, setAuthorized] = useState(app.authorized)
  const logout = useLogout()
  useInactivityLogout(60 * 60 * 1000);

  useEffect(() => {
    const onAuthorize = () => setAuthorized(app.authorized)
    const onLogout = () => {
      return logout()
    }

    app
      .onReady()
      .then(() => (!app.authorized ? undefined /*onLogout()*/ : undefined))

    app.on(ApplicationEvents.AUTHORIZE, onAuthorize)
    app.on(ApplicationEvents.LOGOUT, onLogout)
    return () => {
      app.removeListener(ApplicationEvents.LOGOUT, onLogout)
      app.removeListener(ApplicationEvents.AUTHORIZE, onAuthorize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app, logout])
  return (
    <>
      <Routes>
        <Route
          path="/sign-in"
          element={
            <PublicRoutes authorized={authorized}>
              <SignIn />
            </PublicRoutes>
          }
        />

        <Route path="/*" element={<PrivateRoutes />} />
      </Routes>
    </>
  )
}

export default App
